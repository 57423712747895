import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { UserLoginData } from '@app/types/ResponseType';

interface ProfileInfoProps {
  profileData: UserLoginData | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
      {/* <S.ImgWrapper> */}
      <S.AvatarWrapper>
        <BaseAvatar size={60} style={{ backgroundColor: '#01509A' }}>
          {profileData.firstName.at(0)?.toUpperCase()}
        </BaseAvatar>
      </S.AvatarWrapper>

      {/* </S.ImgWrapper> */}
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.email}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
      {/* <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
