import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface SalesBarChart {
  labels: string[];
  data: number[];
}

export const SalesBarChart: React.FC<SalesBarChart> = (props: SalesBarChart) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const [data, setData] = useState<{ data1: number[]; xAxisData: string[] }>({
    data1: [],
    // data2: [],
    xAxisData: [],
  });

  useEffect(() => {
    const xAxisData: string[] = [];
    const data1: number[] = [];
    // const data2: number[] = [];
    const startDate = new Date();

    //Sample Data
    setTimeout(() => {
      for (let i = 30; i < 100; ) {
        xAxisData.push(new Date().setDate(startDate.getDate() + i).toLocaleString());
        data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
        // data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
        i = i + 10;
      }
      setData({ data1, xAxisData });
    }, 200);
  }, []);

  // setData({ data1: props.data, xAxisData: props.labels });

  const option = {
    legend: {
      data: ['Sales'],
      left: 20,
      top: 0,
      textStyle: {
        color: themeObject[theme].textMain,
      },
    },
    grid: {
      left: 20,
      right: 20,
      bottom: 0,
      top: 70,
      containLabel: true,
    },
    tooltip: {},
    xAxis: {
      data: props.labels,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      name: t('charts.averageValue'),
      nameTextStyle: {
        padding: [0, -24],
        align: 'left',
      },
    },
    series: [
      {
        name: 'Sales',
        type: 'bar',
        data: props.data,
        color: themeObject[theme].chartColor2,
        emphasis: {
          focus: 'series',
        },
        animationDelay: (idx: number) => idx * 10,
      },
      // {
      //   name: t('charts.males'),
      //   type: 'bar',
      //   data: data.data2,
      //   color: themeObject[theme].chartColor3,
      //   emphasis: {
      //     focus: 'series',
      //   },
      //   animationDelay: (idx: number) => idx * 10 + 100,
      // },
    ],
    animationEasing: 'elasticOut',
  };
  return (
    <BaseCard padding="0 0 1.875rem" title={'Sales'}>
      <BaseChart option={option} />
    </BaseCard>
  );
};
