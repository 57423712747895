import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import { setUser, setUserData } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistTokenDemo, readTokenDemo } from '@app/services/localStorage.service';
import { Sale, Stats } from '@app/types/ResponseType';

export interface SaleSlice {
  sales: { sales: Sale[] | null; stats: Stats };
}

const initialState: SaleSlice = {
  sales: { sales: [], stats: {} },
};
export const setSales = createAction<PrepareAction<{ sales: Sale[] | null; stats: Stats }>>(
  'sales/setSales',
  (salesList) => {
    return {
      payload: salesList,
    };
  },
);

// export const salesAsyncThunk = createAsyncThunk('sales/getSales', async (salesList: Sale[], { dispatch }) => {
//   return {
//     payload: salesList,
//   };
// });

const saleSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSales, (state, action) => {
      state.sales = action.payload;
    });
  },
});

export default saleSlice.reducer;
