import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
  KeyOutlined,
  ReadOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'dashboard-menu',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Profile',
    key: 'profile-menu',
    url: '/profile/personal-info',
    icon: <UserOutlined />,
  },
  // {
  //   title: 'Media',
  //   key: 'media',
  //   url: '/medical-dashboard',
  //   icon: <PlaySquareOutlined />,
  //   children: [
  //     {
  //       title: 'Movies',
  //       key: 'create-key',
  //       url: '/apps/feed',
  //     },
  //     {
  //       title: 'Songs',
  //       key: 'revoke-key',
  //       url: '/apps/feed',
  //     },
  //   ],
  // },
];
