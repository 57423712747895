import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { signUpUser, verifyOtp } from '@app/api/user.api';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  roles: [string];
  phoneNumber: number;
  otp: number;
}

const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: false,
};

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [signUpDataSent, setSignUpDataSentStatus] = useState(false);
  const [signupSuccsessFul, setsignupSuccsessFul] = useState(false);
  const [signUpMail, setSignUpMail] = useState('');

  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    setSignUpDataSentStatus(true);

    if (signUpDataSent && signupSuccsessFul) {
      //Otp Data sumbit

      const otpData = {
        otp: values.otp,
        email: signUpMail,
      };
      verifyOtp(otpData).then((response) => {
        if (response.status === 200) {
          setLoading(false);
          navigate('/auth/login');
        }
      });
      return;
    }

    //Signup data sent
    const userValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      roles: 'content_creator',
    };
    //For OTP Form
    setSignUpMail(values.email);

    signUpUser(userValues).then((data) => {
      console.log(data);
      if (data.status === 200) {
        setLoading(false);
        //displays otp field
        setsignupSuccsessFul(true);

        dispatch(doSignUp(values))
          .unwrap()
          .then(() => {
            notificationController.success({
              message: 'OTP Sent Successfully',
              // description: t('auth.signUpSuccessDescription'),
            });
          })
          .catch((err) => {
            notificationController.error({ message: err.message });
            setLoading(false);
          });
      }
    });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('common.signUp')}</S.Title>
        {!signUpDataSent && (
          <>
            <Auth.FormItem
              name="firstName"
              label={'First Name'}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput placeholder={'First Name'} />
            </Auth.FormItem>
            <Auth.FormItem
              name="lastName"
              label={'Last Name'}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput placeholder={'Last Name'} />
            </Auth.FormItem>

            <Auth.FormItem
              name="email"
              label={t('common.email')}
              rules={[
                { required: true, message: t('common.requiredField') },
                {
                  type: 'email',
                  message: t('common.notValidEmail'),
                },
              ]}
            >
              <Auth.FormInput placeholder={t('common.email')} />
            </Auth.FormItem>
            <Auth.FormItem
              label={t('common.password')}
              name="password"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInputPassword placeholder={t('common.password')} />
            </Auth.FormItem>
            <Auth.FormItem
              label={t('common.confirmPassword')}
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: t('common.requiredField') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('common.confirmPasswordError')));
                  },
                }),
              ]}
            >
              <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
            </Auth.FormItem>
            <Auth.ActionsWrapper>
              <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
                <Auth.FormCheckbox>
                  <Auth.Text>
                    {t('signup.agree')}{' '}
                    <Link to="https://reeliv.co/terms/" target={'_blank'}>
                      <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                    </Link>{' '}
                    and{' '}
                    <Link to="https://reeliv.co/privacy-policy-2/" target={'_blank'}>
                      <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                    </Link>
                  </Auth.Text>
                </Auth.FormCheckbox>
              </BaseForm.Item>
            </Auth.ActionsWrapper>
          </>
        )}

        {signUpDataSent && (
          <Auth.FormItem
            name="otp"
            label={'OTP'}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'regexp',
                pattern: /\d{6}/,
                message: 'Not a Valid Number',
              },
            ]}
          >
            <Auth.FormInput placeholder="OTP" />
          </Auth.FormItem>
        )}

        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('signup.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
