import axios from 'axios';
import { API_BASE_URL } from './config/config';
import { DashboardResponse, ResponseData, Sale, Stats, TopSelling, UserLoginData } from '@app/types/ResponseType';
import { httpApi } from './http.api';
import { readToken } from '@app/services/localStorage.service';

export const signUpUser = async (createUserData: any) => {
  return axios.post(API_BASE_URL + '/user/createUser', createUserData);
};
export const verifyOtp = async (verifyData: { otp: number; email: string }) => {
  return axios.post(API_BASE_URL + '/user/verify/otp', verifyData);
};
export const login = async (loginData: any) => {
  return axios.post<ResponseData<UserLoginData[]>>(API_BASE_URL + '/user/login', loginData);
};
export const forgotPassword = async (email: string) => {
  return axios.post<ResponseData<unknown>>(API_BASE_URL + '/user/forgotpassword', { email: email });
};
export const getContentOwnerSales = async (salesData: { startDate: string; endDate: string }) => {
  return axios.post<ResponseData<{ sales: Sale[]; stats: Stats }>>(
    API_BASE_URL + '/sales/contentOwnerSalesData',
    salesData,
    {
      headers: {
        authorization: readToken(),
      },
    },
  );
};
export const getDashBoardData = async () => {
  return axios.get<ResponseData<DashboardResponse>>(API_BASE_URL + '/contentCreator/dashboard', {
    headers: {
      authorization: readToken(),
    },
  });
};
export const getTopSellingSongs = async () => {
  return axios.get<ResponseData<TopSelling[]>>(API_BASE_URL + '/contentCreator/topSellingContent', {
    headers: {
      authorization: readToken(),
    },
  });
};
