import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Product, Sale } from '@app/types/ResponseType';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};
interface SalesTableProps {
  saleList: Sale[];
}
export const SalesTable: React.FC<SalesTableProps> = (props: SalesTableProps) => {
  const [tableData, setTableData] = useState<{ data: Sale[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      if (isMounted.current) {
        setTableData({ data: props.saleList, pagination: { current: 1, pageSize: 5, total: 5 }, loading: false });
      }
    },
    [isMounted, props?.saleList],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  // const handleDeleteRow = (rowId: number) => {
  //   setTableData({
  //     ...tableData,
  //     data: tableData.data.filter((item) => item.key !== rowId),
  //     pagination: {
  //       ...tableData.pagination,
  //       total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
  //     },
  //   });
  // };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
    },
    {
      title: 'Tagged Date',
      dataIndex: 'taggedDate',
    },
    {
      title: 'Sales Date',
      dataIndex: 'saleDate',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      render: (text: Product) => <span>{text.productname}</span>,
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      render: (text: string) => <span>₹{text}</span>,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      // scroll={{ x: 800 }}
      bordered
    />
  );
};
