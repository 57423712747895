import React from 'react';

export const FilterIcon: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4203 1.93663H9.24302C9.00828 0.850945 8.01062 0 6.86624 0C5.69253 0 4.72421 0.821602 4.48947 1.93663H0.528172C0.234743 1.93663 0 2.17138 0 2.46481C0 2.75823 0.234743 2.99298 0.528172 2.99298H4.48947C4.72421 4.07867 5.72187 4.92961 6.86624 4.92961C8.03996 4.92961 9.00828 4.10801 9.24302 2.99298H21.4203C21.7137 2.99298 21.9485 2.75823 21.9485 2.46481C21.9485 2.17138 21.7137 1.93663 21.4203 1.93663ZM6.86624 3.87327C6.10333 3.87327 5.45778 3.25706 5.45778 2.46481C5.45778 1.70189 6.10333 1.05635 6.86624 1.05635C7.62916 1.05635 8.2747 1.67255 8.2747 2.46481C8.2747 3.22772 7.6585 3.87327 6.86624 3.87327Z"
      fill="currentColor"
    />
    <path
      d="M21.5615 9.15502H16.9547C16.7199 8.06933 15.7223 7.21838 14.5779 7.21838C13.4042 7.21838 12.4359 8.03999 12.2011 9.15502H0.528172C0.234743 9.15502 0 9.38976 0 9.68319C0 9.97662 0.234743 10.2114 0.528172 10.2114H12.1718C12.4065 11.297 13.4042 12.148 14.5486 12.148C15.7223 12.148 16.6906 11.3264 16.9253 10.2114H21.5615C21.8549 10.2114 22.0897 9.97662 22.0897 9.68319C22.0897 9.38976 21.8549 9.15502 21.5615 9.15502ZM14.5486 11.121C13.7856 11.121 13.1401 10.5048 13.1401 9.71253C13.1401 8.92027 13.7856 8.30407 14.5486 8.30407C15.3115 8.30407 15.957 8.92027 15.957 9.71253C15.957 10.5048 15.3408 11.121 14.5486 11.121Z"
      fill="currentColor"
    />
  </svg>
);
